import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const InfoCardStyles = styled.div`
  .card-container {
    position: relative;
    width: 350px;
    height: 350px;
    margin-bottom: 15px;
    border: solid 1px #5595f7;

    @media (max-width: 1340px) {
      width: 270px;
      height: 270px;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .card-info {
      position: absolute;
      width: 100%;
      bottom: 0;
      text-align: center;
      padding-bottom: 20px;
      background-color: rgba(85, 149, 247, 0.8);
      color: white;

      h4 {
        margin: 0;
      }
      p {
        margin: 0;
        padding: 5px;
      }
      button {
        background-color: transparent;
        border: 2px solid white;
        color: white;
        font-size: small;
        padding: 6px;

        &:hover {
          background-color: #5595f7;
          cursor: pointer;
        }
      }
    }
  }
`

const InfoCard = props => {
  return (
    <InfoCardStyles>
      <Link to={props.link}>
        <div className="card-container">
          <img src={props.image} alt="" />
          <div className="card-info">
            <h4>{props.title}</h4>
            <p>{props.info}</p>
            <p>{props.tank}</p>
            <p>{props.time}</p>

            <button type="button">{props.btnText}</button>
          </div>
        </div>
      </Link>
    </InfoCardStyles>
  )
}

export default InfoCard
