import React, { Component } from "react"
import { ApplicationSectionStyles } from "./styles/ApplicationSectionStyles"
import Image1 from "../images/timberline-28-DISK-SCRUBBER-aviation.jpg"
import Image2 from "../images/timberline-28-ORBITAL-SCRUBBER-education.jpg"
import Image3 from "../images/timberline-24-ORBITAL-SCRUBBER-hospital.jpg"
import Image4 from "../images/timberline-28-ORBITAL-SCRUBBER-MANUFACTURING-FACILITY.807457031.jpg"

import AviationImageThumb from "../images/timberline-aviation-small.jpg"
import AviationImageLarge from "../images/timberline-aviation-large.jpg"
import EducationImageThumb from "../images/timberline-education-small.jpg"
import EducationImageLarge from "../images/timberline-education-large.jpg"
import HealthcareImageThumb from "../images/timberline-healthcare-small.jpg"
import HealthcareImageLarge from "../images/timberline-healthcare-large.jpg"
import ElectricalImageThumb from "../images/timberline-electric-lab-small.jpg"
import ElectricalImageLarge from "../images/timberline-electric-lab-large.jpg"

class ApplicationSection extends Component {
  state = {
    changeImage: AviationImageLarge,
  }

  handleClick = e => {
    e.preventDefault()
    this.setState({ changeImage: e.target.dataset.image })
  }
  render() {
    return (
      <ApplicationSectionStyles>
        <div id="applications-container">
          <div id="left-container">
            <h1>Markets We Serve</h1>
            <div id="list-container">
              <ul>
                <li>Aviation</li>
                <li>Education</li>
                <li>Food and Beverage</li>
                <li>Government</li>
              </ul>
              <ul>
                <li>Health Care</li>
                <li>Janitorial</li>
                <li>Manufacturing</li>
                <li>Municipalities</li>
              </ul>
              <ul>
                <li>Parking Facilities</li>
                <li>Retail and Hospitality</li>
                <li>Warehouse / Distribution</li>
              </ul>
            </div>
            <div id="thumbnail-container">
              <div className="thumbnail" onClick={this.handleClick}>
                <img
                  src={AviationImageThumb}
                  alt=""
                  data-image={AviationImageLarge}
                />
                <div className="thumb-info" data-image={Image1}>
                  <h4 data-image={Image1}>Aviation</h4>
                </div>
              </div>
              <div className="thumbnail" onClick={this.handleClick}>
                <img
                  data-image={EducationImageLarge}
                  src={EducationImageThumb}
                  alt=""
                />
                <div className="thumb-info" data-image={Image2}>
                  <h4 data-image={Image2}>Education</h4>
                </div>
              </div>
              <div className="thumbnail" onClick={this.handleClick}>
                <img
                  data-image={HealthcareImageLarge}
                  src={HealthcareImageThumb}
                  alt=""
                />
                <div className="thumb-info" data-image={Image3}>
                  <h4 data-image={Image3}>Healthcare</h4>
                </div>
              </div>
              <div className="thumbnail" onClick={this.handleClick}>
                <img
                  data-image={ElectricalImageLarge}
                  src={ElectricalImageThumb}
                  alt=""
                />
                <div className="thumb-info" data-image={Image4}>
                  <h4 data-image={Image4}>Electronics Lab</h4>
                </div>
              </div>
            </div>
          </div>

          <div id="right-container">
            <img src={this.state.changeImage} alt="" />
          </div>
        </div>
      </ApplicationSectionStyles>
    )
  }
}

export default ApplicationSection
