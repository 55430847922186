import React from "react"
import Video from "../images/ThisIsTimberline_1.mp4"
import styled from "styled-components"
import CoverImage from "../images/timberline-video-cover.jpg"
import { Link } from "gatsby"

const VideoStyles = styled.div`
  width: 100%;
  height: 800px;
  margin: auto;
  iframe {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }
  @media (max-width: 1425px) {
    height: 600px;
  }

  @media (max-width: 1024px) {
    height: 500px;
  }

  @media (max-width: 380px) {
    height: 300px;
  }

  /* #video {
    width: 100%;
    height: 100%;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 1100px) {
      display: none;
    }
  }

  .photo-link {
    width: 100%;
    height: 100%;
    display: none;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 1100px) {
      display: block;
    }
  } */
`

const VideoComponent = () => {
  return (
    <VideoStyles>
      <iframe
        src="https://www.youtube.com/embed/Nz6X7CRPwdw"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen="allowFullScreen"
      ></iframe>
      {/* <div id="video">
        <video
          className="video"
          loop
          playsinline
          muted
          controls
          poster={CoverImage}
        >
          <source src={Video} type="video/mp4" />
        </video>
      </div> */}
    </VideoStyles>
  )
}

export default VideoComponent
