import styled from "styled-components"

export const ApplicationSectionStyles = styled.div`
  #applications-container {
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    min-height: 450px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: -ms-flexbox;
      height: 500px;
    }

    @media (max-width: 1200px) {
      grid-template-columns: 100%;
    }

    @media (max-width: 675px) {
      grid-template-columns: 100%;
      min-height: 250px;
    }

    #left-container {
      position: relative;
      height: 100%;
      background-color: #5595f7;
      z-index: 2;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 50%;
        height: 500px;
      }

      h1 {
        text-align: center;
        color: white;
      }

      #list-container {
        color: white;
        display: flex;
        justify-content: space-around;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          display: -ms-flexbox;
        }

        @media (max-width: 1200px) {
          height: 400px;
          font-size: small;
        }

        @media (max-width: 400px) {
          height: 50px;
          font-size: small;
        }
      }
      #thumbnail-container {
        display: grid;
        grid-template-columns: auto auto auto auto;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: orange;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          display: -ms-flexbox;
        }

        @media (max-width: 675px) {
          display: none;
        }

        .thumbnail {
          position: relative;
          height: 250px;
          filter: grayscale(80%);

          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            width: 25%;
          }

          @media (max-width: 675px) {
            display: none;
          }

          &:hover {
            cursor: pointer;
            filter: grayscale(0%);
            border: 2px solid #5595f7;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .thumb-info {
            position: absolute;
            bottom: 0;
            width: 100%;
            background: rgba(0, 0, 0, 0.5);
            text-align: center;
            color: white;
          }
        }
      }

      @media (max-width: 675px) {
        height: 250px;
      }
    }

    #right-container {
      height: 100%;
      background-color: green;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 50%;
      }

      @media (max-width: 675px) {
        display: none;
      }

      img {
        width: 100%;

        height: 450px;
        object-fit: cover;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          height: 500px;
        }

        @media (max-width: 675px) {
          display: none;
        }
      }
    }
  }
`
