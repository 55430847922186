import React from "react"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import MainLayout from "../components/MainLayout"
import HeroImage from "../images/timberline-hero-image-new.jpg"
import InfoCard from "../components/InfoCard"
import {
  HeroImageStyle,
  HomePageSection2,
} from "../components/styles/IndexStyles"
import FloorScrubberImage from "../images/timberline-floor-scrubbers-lineup.jpg"
import PartsServiceImage from "../images/timberline-service-toptile.jpg"
import WarrantyImage from "../images/timberline-warranty-toptile.jpg"
import IndexSection3 from "../components/IndexSection3"
import ApplicationSection from "../components/ApplicationSection"

import VideoComponent from "../components/VideoComponent"

const IndexPage = () => {
  return (
    <>
      <SEO title="Home" />
      <Helmet>
        <title>
          Commercial Floor Scrubbers | Timberline Cleaning Equipment
        </title>
        <description></description>
      </Helmet>

      <MainLayout>
        <HeroImageStyle>
          <div id="hero-image-container">
            <img
              src={HeroImage}
              alt="m24-orbital-floor-scrubber-cleans-grocery-store"
            />
          </div>
          <a href="#demo-form">
            <button>Free Trial</button>
          </a>
        </HeroImageStyle>
        <HomePageSection2>
          <div className="card-section">
            <InfoCard
              title="Our Floor Scrubbers"
              info="Explore our range of cleaning equipment"
              btnText="Learn More"
              image={FloorScrubberImage}
              link="/floor-scrubbers"
            />
            <InfoCard
              title="Parts & Service"
              info="Looking for a part or service work?"
              btnText="Learn More"
              image={PartsServiceImage}
              link="/support"
            />
            <InfoCard
              title="Warranty"
              info="Need help or have a warranty related question?"
              btnText="Learn More"
              image={WarrantyImage}
              link="/warranty"
            />
          </div>
        </HomePageSection2>
        <ApplicationSection />
        <VideoComponent />
        <div id="demo-form">
          <IndexSection3 title="Request a Free Demo at your Facility" />
        </div>
      </MainLayout>
    </>
  )
}

export default IndexPage
