import styled from "styled-components"

//-----Hero Image Styles---------
export const HeroImageStyle = styled.div`
  #hero-image-container {
    position: relative;
    img {
      width: 100vw;
      height: 842px;
      object-fit: cover;
    }
  }

  button {
    position: absolute;
    bottom: 13%;
    text-align: center;
    padding: 10px;
    /* width: 200px; */
    background-color: #f7931e;
    border: white 2px solid;
    color: white;
    left: 50%;
    width: 300px;
    font-size: 3em;
    margin-left: -150px;
    &:hover {
      cursor: pointer;
    }
  }
`
//----END Hero Image-------------

//-----Index Section 2 styles
export const HomePageSection2 = styled.div`
  padding: 30px;
  background-color: whitesmoke;
  .card-section {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @media (max-width: 1190px) {
      width: 100%;
    }
  }
`
//------END Section 2 styles--------------
